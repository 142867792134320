/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}

@font-face {
font-family: '__franklinGothic_bd1aab';
src: url(/_next/static/media/a51031dcb3a725d3-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__franklinGothic_Fallback_bd1aab';src: local("Arial");ascent-override: 83.64%;descent-override: 26.99%;line-gap-override: 22.13%;size-adjust: 90.39%
}.__className_bd1aab {font-family: '__franklinGothic_bd1aab', '__franklinGothic_Fallback_bd1aab'
}.__variable_bd1aab {--franklin: '__franklinGothic_bd1aab', '__franklinGothic_Fallback_bd1aab'
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/d318e3c38dd18566-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/c50441fe305bcdc9-s.p.woff) format('woff');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/09f09e24ac55d1be-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/588cf2979075326b-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/ea5cb9d0e74ce9d2-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/03069f8cd73ce5b3-s.p.woff) format('woff');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/fc832a6d8643a2f3-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/5e7b6cfd12b9ddff-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/82255ad28689c94c-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/58002a11b5c21dba-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/c99ffa392d77a1c6-s.p.woff) format('woff');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/cc5618f6544de2a6-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/64be8a17bd477f51-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__gelica_775e62';
src: url(/_next/static/media/44fb6fb44a75affb-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__gelica_Fallback_775e62';src: local("Arial");ascent-override: 90.44%;descent-override: 24.18%;line-gap-override: 0.00%;size-adjust: 103.38%
}.__className_775e62 {font-family: '__gelica_775e62', '__gelica_Fallback_775e62'
}.__variable_775e62 {--gelica: '__gelica_775e62', '__gelica_Fallback_775e62'
}

@font-face {
font-family: '__proximaNova_88e526';
src: url(/_next/static/media/a02c78ad97fb5038-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_88e526';
src: url(/_next/static/media/44138f63d74cd208-s.p.woff) format('woff');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_88e526';
src: url(/_next/static/media/fb2bb849f02e081b-s.p.woff) format('woff');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__proximaNova_88e526';
src: url(/_next/static/media/2b66d58a796290cd-s.p.woff) format('woff');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: '__proximaNova_Fallback_88e526';src: local("Arial");ascent-override: 92.82%;descent-override: 30.07%;line-gap-override: 0.00%;size-adjust: 99.12%
}.__className_88e526 {font-family: '__proximaNova_88e526', '__proximaNova_Fallback_88e526'
}.__variable_88e526 {--proxima-nova: '__proximaNova_88e526', '__proximaNova_Fallback_88e526'
}

